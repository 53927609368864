.services {
    background-color: $light-white;
    margin-top: 40px;
    padding: 32px 0;
    @media screen and (min-width: 1024px) {
        margin-top: 60px;
    }
    .service-container {
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        gap: 24px;
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
        .service-card-container {
            width: 60%;
            min-width: 175px;
            @media screen and (min-width: 768px) {
                width: 16%;
                min-width: unset;
            }

            .service-component {
                @include flex-center;
                flex-direction: column;
                .service-image {
                    width: 32px;
                    height: 32px;
                }
                .service-content {
                    .service-title {
                        @include regular-font;
                        text-align: center;
                        color: $main-dark-blue;
                    }
                    .service-desc {
                        @include regular-font;
                        text-align: center;
                        color: $dark-gray;
                    }
                }
            }
        }
    }
    .service-container::-webkit-scrollbar {
        display: none;
    }
}
