.details {
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 42px;
    gap: 20px;
    @media screen and (min-width: 1023px) {
        flex-direction: row;
    }

    .car-view-mobile {
        @media screen and (min-width: 768px) {
            display: none;
        }

        .car-view-swiper {
            position: relative;
            .swiper-pagination {
                width: auto;
                left: 15px !important;

                .swiper-pagination-bullet {
                    height: 8px;
                    background-color: $white !important;
                    opacity: 1;
                }
                .swiper-pagination-bullet-active {
                    width: 25px;
                    background-color: $main-blue !important;
                    border-radius: 10px !important;
                }
            }

            .swiper-button-prev {
                display: inline-block;
                color: $medium-black;
            }
            .swiper-button-next {
                display: inline-block;
                color: $medium-black;
            }
        }

        .carousel-image {
            height: 213px;
            position: relative;
        }
        .car-image.border {
            border: 1px solid red;
        }
        .top-btn-container {
            position: absolute;
            bottom: 16px;
            right: 15px;
            z-index: 100;
            @include flex-center;
            gap: 16px;
            .top-btn {
                @include flex-center;
                justify-content: center;
                background-color: rgba(255, 255, 255, 0.7);
                border-radius: 5px;
                cursor: pointer;
                width: 32px;
                height: 32px;
                img {
                    height: 24px;
                    width: 24px;
                }
            }
            .pause-icon {
                width: 24px;
                height: 24px;
            }
        }
    }

    .car-view {
        display: none;
        position: relative;
        @media screen and (min-width: 768px) {
            display: flex;
            flex-direction: column;
        }
        @media screen and (min-width: 1024px) {
            width: calc(63.3% - 20px);
        }
        .card-status {
            background-color: #175adc;
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px 8px 5px 8px;
            border-radius: 0px 5px 0px 5px;
            align-items: center;
            justify-content: center;
            z-index: 2;
        }
        .card-status-new {
            position: absolute;
            top: 16px;
            left: 16px;
            align-items: center;
            justify-content: center;
            z-index: 2;
            height: 64px;
            width: 64px;
        }

        .card-status-text {
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            color: #ffffff;
        }
        .top-car {
            width: 100%;
            height: 452px;
            border-radius: 5px;
            margin-bottom: 22px;
            position: relative;
            img {
                border-radius: 5px;
            }
            .btn-container {
                position: absolute;
                bottom: 26px;
                @include flex-center;
                justify-content: space-between;
                padding-inline: 24px;
                width: 100%;
                z-index: 22;
                .left {
                    @include flex-center;
                    gap: 24px;
                }
                .top-btn {
                    @include flex-center;
                    justify-content: center;
                    padding: 8px 15px 8px 12px;
                    gap: 10px;
                    background-color: rgba(255, 255, 255, 0.7);
                    border-radius: 5px;
                    @include regular-font;
                    color: $medium-black;
                    height: 50px;
                    cursor: pointer;
                }
                .threesixty {
                    left: 24px;
                }
                .play {
                    right: 24px;
                    .pause-icon {
                        width: 24px;
                    }
                }
                .photo-preview {
                    display: none;
                }
            }
            .car-images-slider {
                .swiper-button-next {
                    top: 50%;
                }
            }
        }
        .carousel {
            .car-images-slider-thumbs {
                .swiper-slide {
                    border: 2px solid transparent;
                    width: 100%;
                    height: 100px;
                    border-radius: 5px;

                    img {
                        border-radius: 5px;
                    }
                    .car-images-slider-thumbs-wrapper {
                        height: 96px;
                    }
                }
                .swiper-slide-thumb-active {
                    border: 2px solid $main-dark-blue;
                }

                .swiper-button-next {
                    top: 50%;
                }
            }
        }
    }
    .car-details {
        .car-detail {
            border-radius: 5px;
            margin-bottom: 24px;
            @media screen and (min-width: 768px) {
                padding: 24px;
                background: #f5f9ff;
            }
            .details-top__old-price {
                margin-bottom: 16px;
                .details-top {
                    @include flex-center;
                    justify-content: space-between;
                    .like-share {
                        @include flex-center;
                        gap: 18px;
                    }
                }
                .subtitle {
                    text-decoration-line: underline;
                    color: #175adc;
                    border-radius: 4px;
                    cursor: pointer;
                }
                .old-price {
                    @include regular-font;
                    margin-bottom: 3px;
                    color: $main-dark-blue;
                }
            }
            .new-price {
                font-family: 'Sofia-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 140%;
                color: $main-blue;
            }
            .old-price {
                font-family: 'Sofia-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 140%;
                color: $main-blue;
            }
            .old-price-no-discount {
                font-family: 'Sofia-Bold';
                color: #8B8B8B;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-decoration: line-through;
                text-decoration-line: strikethrough;
            }
            .installment {
                @include regular-font;
                color: $dark-blue-tint1;
                margin-bottom: 16px;
            }
            .price-features {
                @include flex-center;
                gap: 12px;

                .hot-deal {
                    background: #091731;
                    border-radius: 5px;
                    margin-right: 2px;
                    @include flex-center;
                    flex-direction: row;
                    padding: 8px 14px 8px 8px;
                    gap: 6px;
                    height: 42px;
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;
                    color: $white;
                    margin-bottom: 24px;
                }
            }

            .car-deets {
                @include flex-center;
                flex-direction: column;
                justify-content: center;
                padding: 15px;
                background-color: $light-white;
                gap: 13px;
                border-radius: 5px;
                margin-bottom: 24px;
                @media screen and (min-width: 768px) {
                    background: $white;
                    align-items: flex-start;
                }
                @media screen and (min-width: 1024px) {
                    padding: 5px;
                }
                @media screen and (min-width: 1280px) {
                    padding: 15px;
                }
                .car-name {
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 140%;
                    color: $main-dark-blue;
                }
                .car-features-container {
                    width: 100%;
                    .car-features {
                        @include flex-center;
                        flex-wrap: wrap;
                        margin-bottom: 25px;
                        .features-container {
                            @include flex-center;
                            gap: 10px;
                            padding-right: 10px;
                            font-family: 'Sofia-Regular';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 14px;
                            color: #595959;
                            text-transform: capitalize;
                            @media screen and (min-width: 768px) {
                                padding-right: 14px;
                            }
                            @media screen and (min-width: 1024px) {
                                padding-right: 5px;
                                gap: 5px;
                            }
                            @media screen and (min-width: 1440px) {
                                padding-right: 10px;
                                gap: 10px;
                            }
                            .features-icons {
                                width: 17px;
                                height: 17px;
                            }
                        }
                        .features-container:not(:last-child) {
                            border-right: 1px solid #b3b3b3;
                        }
                        .features-container:not(:first-child) {
                            padding-left: 7px;
                            @media screen and (min-width: 768px) {
                                padding-left: 10px;
                            }
                            @media screen and (min-width: 1024px) {
                                padding-left: 5px;
                            }
                            @media screen and (min-width: 1200px) {
                                padding-left: 5px;
                            }
                        }
                    }
                    .card-bottom {
                        @include flex-center;
                        gap: 12px;
                        @media screen and (min-width: 1024px) {
                            gap: unset;
                            justify-content: space-between;
                        }
                        .card-cert {
                            @include flex-center;
                            img {
                                width: 24px;
                                margin-right: 6px;
                            }

                            span {
                                font-family: 'Sofia-Regular';
                                font-size: 14px;
                                color: $success;
                            }
                        }
                        .card-emi-type {
                            font-family: 'Sofia-Regular';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 14px;
                            color: $dark-gray;
                        }
                        .dot {
                            background-color: $grey;
                        }
                    }
                }
                .fre {
                    .car-features {
                        .features-container {
                            gap: 5px;
                            @media screen and (min-width: 768px) {
                                padding-right: 10px;
                            }
                        }
                    }
                }
                .car-bottom {
                    @include flex-center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    .iscertified {
                        @include flex-center;
                        font-family: 'Sofia-Bold';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 14px;
                        color: #009e60;
                        gap: 8px;
                        .certified-logo {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
            .btn-container {
                display: flex;
                justify-content: space-between;
                .car-btn {
                    width: 48%;
                    gap: 16px;
                    height: 50px;
                    border-radius: 5px;
                    @include regular-font;
                    color: $white;
                }
                .buy-now {
                    background: #175adc;
                    cursor: pointer;
                }
                .whatsapp {
                    @include flex-center;
                    flex-direction: row;
                    justify-content: center;
                    padding: 8px 15px 8px 12px;
                    gap: 10px;
                    background: linear-gradient(0deg, #20b038, #20b038), #ffffff;
                    .whatsapp-logo-container {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
            .car-viewed {
                display: flex;
                text-align: center;
                gap: 10px;
                @include regular-font;
                color: $light-black;
                margin-top: 25px;
            }
        }
        .post-script-container {
            .post-script {
                @include flex-center;
                gap: 16px;
                @include regular-font;
                color: $medium-black;
                .certified-icon {
                    width: 90px;

                    @media screen and (min-width: 1200px) {
                        width: 60px;
                    }
                }
            }
            .autofast-image {
                margin-top: 30px;
                width: 100px;
                margin-inline: auto;
                @media screen and (min-width: 768px) {
                    margin-inline: unset;
                }
            }
        }
    }
}
.button-wrappers {
    position: sticky;
    bottom: 0;
    background-color: $white;
    padding-top: 15px !important;
    padding-bottom: 25px !important;
    box-shadow: 0px -10px 15px rgba(50, 50, 50, 0.05);
    transition: all 0.2s ease-out;
    z-index: 101;
    @media screen and (min-width: 768px) {
        display: none;
    }
    .car-info {
        @include flex-center;
        justify-content: space-between;
        margin-bottom: 16px;
        .car-name {
            @include regular-font;
            text-align: center;
            color: $dark-blue-tint1;
        }
        .price {
            font-family: Sofia-Regular;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            color: $main-dark-blue;
        }
    }
    .button-container {
        display: flex;
        justify-content: space-between;
        .car-btn {
            width: 48%;
            gap: 16px;
            height: 50px;
            border-radius: 5px;
            @include regular-font;
            color: $white;
        }
        .buy-now {
            background: #175adc;
            cursor: pointer;
        }
        .whatsapp {
            @include flex-center;
            flex-direction: row;
            justify-content: center;
            padding: 8px 15px 8px 12px;
            gap: 10px;
            background: linear-gradient(0deg, #20b038, #20b038), #ffffff;
            .whatsapp-logo-container {
                width: 24px;
                height: 24px;
            }
        }
    }
}
.button-wrappers-hide {
    display: none;
}
.ril__image {
    right: unset !important;
    left: 25% !important;
    height: 50% !important;
    width: 50% !important;
    transform: unset !important;
}
.ril__caption {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    background-color: transparent !important;
    font-family: Sofia-Bold;
    font-size: 16px;
    font-weight: 700;
    color: $white;
    line-height: 140%;
}
.lg-sub-html {
    display: inline-block !important;
    background-color: black !important;
    color: white !important;
    font-size: 16px;
    font-family: Sofia-Bold;
    opacity: 0.7 !important;
}
.lg-toolbar {
    #lg-actual-size {
        display: none;
    }
    .lg-download {
        display: none;
    }
}
.lg-grab {
    .lg-thumb {
        display: block !important;
        margin-inline: auto;
    }
}
.threesixty-modal {
    width: 100% !important;
    height: 100% !important;
    max-width: unset !important;
    background-color: transparent !important;
    .ant-modal-close {
        top: 36px;
        .ant-modal-close-x {
            opacity: 10000;
            height: unset;
            line-height: unset;
        }
    }
    .ant-modal-body {
        padding: 0 !important;
        height: 100% !important;
        background-color: #000000;
        iframe {
            height: 99vh !important;
            border: 0;
            .buttons {
                display: none !important;
                background-color: #ffffff !important;
                .info-btn {
                    display: none !important;
                }
            }
        }
    }
}
.with-card {
    display: none !important;
}
.currency-modal {
    font-family: 'Sofia-Bold';
    line-height: 140%;
    font-style: normal;

    .separator {
        width: 70px;
    }
    .currencyex-title {
        color: '#091731';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        font-family: 'Sofia-Bold';
    }
    .subtitle {
        margin-top: 20px;
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 400;
    }
    .name-price {
        .subtitle {
            margin-bottom: 1px;
            text-align: start;
            margin: 0;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .price {
        font-family: 'Sofia-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: #091731;
    }
    .currency-converter {
        margin-top: 25px;
        display: flex;
        justify-content: flex-start;
        gap: 30px;
        .currency-dropdown {
            width: 40%;
            .phone-select {
                width: 100% !important;
            }
        }
        .preview-box {
            width: 40%;
        }
    }
}
