body {
    overflow-x: hidden;
}
.detail-page {
    background-color: $white;
    .loading-detail {
        height: 20vh;
    }
    .hide-logo {
        @media screen and (max-width: 1023px) {
            .navbar-right {
                display: unset;
                right: 15px;
                top: calc(50% - (44px / 2));
            }
        }
    }
    .title {
        margin-bottom: 24px;
    }
    .process-container {
        background-color: $light-white;
        margin-top: 30px;
        padding-bottom: 1px;
    }
    .questionnaire-container {
        background-color: $white;
    }
    .details-certi {
        background-color: $white;
    }
}
.Finance {
    .questionnaire-container {
        background-color: $light-white;
    }
    .padding-15 {
        padding: 15px;
    }
}
.about {
    .process-container {
        background-color: $light-white;
        margin-bottom: 0;
        padding: 60px 0 43px;
    }
    .questionnaire-container {
        background-color: $light-white;
    }
}
.discover {
    padding-block: 40px !important;
    .center {
        @media screen and (min-width: 768px) {
            margin-inline: auto;
        }
    }
    .title {
        text-align: center;
        margin-bottom: 8px;
    }
    .discover-content {
        @include regular-font;
        text-align: center;
        color: $medium-black;
    }
}
.additional {
    .additional-photos {
        @include flex-center;
        justify-content: center;
        gap: 24px;
        .additional-content {
            p {
                @include regular-font;
                text-align: center;
                color: $medium-black;
            }
            .image-container {
                height: 175px;
                img {
                    object-fit: cover;
                }
            }
        }
    }
}
