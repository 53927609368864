.footer-container {
    background-color: $dark-blue-tint1;
    .footer {
        padding-top: 40px;
        padding-bottom: 40px;
        @media screen and (min-width: 768px) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        @media screen and (min-width: 1200px) {
            display: flex;
            gap: 55px;
            justify-content: space-between;
        }
        h4 {
            margin-bottom: 16px;
        }
        .footer-subtitle {
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            color: $white;
            @media screen and (min-width: 768px) {
                font-size: 20px;
            }
        }
        .border-top {
            border: 1px solid $white;
            width: 40px;
            margin-bottom: 8px;
            height: 0;
            background-color: $white;
        }
        .footer-lists {
            list-style: none;
            font-family: 'Sofia-Regular';
            font-style: normal;
            font-size: 13px;
            line-height: 140%;
            color: $white;
            @include flex-center;
            cursor: pointer;
            gap: 12px;
            flex-wrap: wrap;
            text-transform: capitalize;
            margin-bottom: 12px;
            margin-right: 10px;
            a {
                color: $white;
            }
            @media screen and (min-width: 768px) {
                font-size: 16px;
            }
            &:last-child {
                @media screen and (min-width: 1024px) {
                    margin-bottom: 0;
                }
            }
            .footer-list {
                @include flex-center;
                .seperator {
                    border: 0.5px solid #ffffff;
                    margin-left: 12px;
                    height: 14px;
                    display: inline-block;
                }
            }
            .footer-list:last-child {
                .seperator {
                    display: none;
                }
            }
        }
        .footer-top {
            @media screen and (min-width: 1024px) {
                width: 45%;
                margin-bottom: 60px;
            }
            @media screen and (min-width: 1200px) {
                width: 42%;
                margin-bottom: 0;
            }
            .footer-top-top {
                @include flex-center;
                gap: 24px;
                margin-bottom: 30px;
                .footer-logo-container {
                    width: 80px;
                    height: 75px;
                    @media screen and (min-width: 768px) {
                        width: 124px;
                        height: 124px;
                    }
                }
                p {
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 140%;
                    color: $white;
                    width: 75%;
                    @media screen and (min-width: 768px) {
                        width: 30%;
                        font-size: 16px;
                    }
                    @media screen and (min-width: 1024px) {
                        width: 61%;
                    }
                }
            }
            .footer-top-available {
                .footer-top-btn-holder {
                    @include flex-center;
                    gap: 15px;
                    button {
                        @include flex-center;
                        background-color: $black;
                        color: #ffffff;
                    }
                }
            }
        }
        .footer-content {
            @media screen and (min-width: 768px) {
                display: flex;
                align-items: baseline;
                width: 100%;
                gap: 47px;
            }
            @media screen and (min-width: 1200px) {
                width: calc(100% - 31% - 40px);
                gap: 20px;
            }
            @media screen and (min-width: 1440px) {
                width: calc(100% - 31% - (47px * 2));
                gap: 47px;
            }

            .footer-brands {
                margin-top: 32px;
                margin-bottom: 32px;
                @media screen and (min-width: 1024px) {
                    margin-top: 0;
                    width: 26.5%;
                    margin-bottom: 0;
                }
                @media screen and (min-width: 1200px) {
                    width: 32%;
                }
                .footer-brand-lists {
                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                }
                .footer-brand-lists-desktop {
                    display: none;
                    @media screen and (min-width: 768px) {
                        display: inline-block;
                    }
                }
            }
            .footer-quick-links {
                margin-bottom: 32px;
                @media screen and (min-width: 768px) {
                    width: 25%;
                }
                @media screen and (min-width: 1200px) {
                    width: 31%;
                    margin-bottom: 0;
                }
                .quick-links {
                    display: flex;
                    gap: 12px;
                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                    .footer-lists {
                        align-items: flex-start;
                        flex-direction: column;
                        margin-bottom: 12px;
                    }
                }
                .quick-links-desktop {
                    display: none;
                    @media screen and (min-width: 768px) {
                        display: flex;
                        justify-content: space-between;
                    }
                    .footer-lists {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 12px;
                        .footer-list-quick {
                            width: 100%;
                            span {
                                border-bottom: 2px solid transparent;
                                transition: all 0.5s ease-in-out;
                                cursor: pointer;
                            }
                            span:hover {
                                border-bottom: 2px solid $white;
                            }
                            & > a {
                                border-bottom: 2px solid $white;
                                border-bottom: 2px solid transparent;
                                transition: all 0.5s ease-in-out;
                                &:hover {
                                    border-bottom: 2px solid $white;
                                }
                            }
                        }
                    }
                }
            }
            .footer-address-line {
                @media screen and (min-width: 768px) {
                    width: 30%;
                }
                @media screen and (min-width: 1024px) {
                    width: 40%;
                }

                .footer-address {
                    margin-bottom: 16px;
                }
                .footer-email {
                    margin-bottom: 12px;
                    text-decoration: underline;
                    cursor: pointer;
                }
                .footer-number {
                    margin-bottom: 18px;
                    cursor: pointer;
                }
                p {
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-size: 13px;
                    line-height: 140%;
                    color: #ffffff;
                    @media screen and (min-width: 768px) {
                        font-size: 16px;
                    }
                }
                .footer-socials {
                    @include flex-center;
                    gap: 20px;
                    cursor: pointer;
                }
            }
        }
        .footer-copyright {
            background-color: $main-dark-blue;
            @include regular-font;

            line-height: 16px;
            color: #ffffff;
            padding: 18px 0;
        }
    }
    .footer-copyright {
        background-color: $main-dark-blue;
        font-family: 'Sofia-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
        color: $white;
        text-align: center;
        padding: 18px 0;
        @media screen and (min-width: 768px) {
            font-size: 16px;
        }
        p {
            font-weight: 400;
            margin-top: 0.5rem;
        }
    }
    .flag-img {
        width: 25px;
        height: 25px;
    }
    .country-link{
        color: inherit;
    }
}
