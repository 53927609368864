@mixin input-font {
    font-family: 'Sofia-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: $light-black;
    @media screen and (min-width: 768px) {
        font-size: 16px;
    }
}

.hero-section {
    background: url('../../../public/images/hero_image_1.webp') no-repeat;
    background-size: cover;
    height: 70vh;
    width: 100%;
    position: relative;
    background-position: center;

    @media screen and (min-width: 768px) {
        height: 55vw;
    }

    @media screen and (min-width: 1200px) {
        height: 46vw;
    }
    @media screen and (min-width: 1500px) {
        height: 35vw;
    }

    .hero-content {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-35%);
        @media screen and (max-width: 767px) {
            width: 92%;
        }
        .hero {
            margin-inline: auto;
            @media screen and (min-width: 768px) {
                margin-inline: unset;
            }
        }
        .hero-text-container {
            position: relative;
            width: 243px;
            margin: 10px 0 32px;
            @media screen and (max-width: 767px) {
                margin-inline: auto;
            }
            @media screen and (min-width: 768px) {
                width: 50%;
                text-align: left;
                margin-inline: unset;
            }
            @media screen and (min-width: 1024px) {
                font-size: 30px;
                font-size: 62px;
                width: 56%;
            }
            @media screen and (min-width: 1200px) {
                width: 60%;
            }
            @media screen and (min-width: 1280px) {
                width: 55%;
            }

            .hero-text {
                font-family: 'Sofia-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 140%;
                color: $white;
                @media screen and (max-width: 767px) {
                    text-align: center;
                }
                @media screen and (min-width: 768px) {
                    font-size: 30px;
                    text-align: left;
                }
                @media screen and (min-width: 1024px) {
                    font-size: 50px;
                }
            }
            .french-flag {
                width: 44px;
                height: 44px;
                position: absolute;
                right: -22px;
                bottom: -5px;
                @media screen and (min-width: 768px) {
                    right: 22px;
                    bottom: -2px;
                }
                @media screen and (min-width: 1024px) {
                    width: 65px;
                    height: 65px;
                    right: -70px;
                    bottom: 30px;
                }
                @media screen and (min-width: 1200px) {
                    bottom: 25px;
                    right: -25px;
                }
            }
            .ar {
                right: -60px;

                @media screen and (max-width: 768px) {
                    right: 75px !important;
                }
                @media screen and (max-width: 1024px) {
                    right: 120px;
                }
            }
            .fr {
                right: 0;
                bottom: -2px;

                @media screen and (min-width: 768px) {
                    right: -20px;
                    bottom: -2px;
                    left: unset;
                }
                @media screen and (min-width: 1024px) {
                    bottom: 30px;
                    right: 0;
                }
                @media screen and (min-width: 1200px) {
                    bottom: 25px;
                }
            }
        }
        .fre {
            width: 242px;
            @media screen and (max-width: 767px) {
                width: 340px;
            }
            @media screen and (min-width: 768px) {
                width: 50%;
                text-align: left;
                margin-inline: unset;
            }
            @media screen and (min-width: 1024px) {
                font-size: 30px;
                font-size: 62px;
                width: 56%;
            }
            @media screen and (min-width: 1200px) {
                width: 60%;
            }
            @media screen and (min-width: 1280px) {
                width: 55%;
            }
            .hero-text {
                @media screen and (max-width: 767px) {
                    text-align: center;
                }
            }
        }
        .search-form {
            width: 100%;
            height: 54px;
            position: relative;
            border-radius: 5px;
            margin-bottom: 28px;
            @media screen and (min-width: 768px) {
                width: 610px;
            }
            @media screen and (min-width: 1200px) {
                height: 66px;
            }
            .search-input {
                width: 100%;
                height: 100%;
                padding: 7px;
                background-color: $white;
                position: relative;
                border-radius: 5px;
                @include input-font;
                font-size: 16px;
                @media screen and (min-width: 768px) {
                    padding: 12px;
                }
            }
            .search-input::placeholder {
                @include input-font; /* Firefox */
                @media screen and (min-width: 768px) {
                    font-size: 16px;
                }
            }
            .search-input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                @include input-font; /* Firefox */
                @media screen and (min-width: 768px) {
                    font-size: 16px;
                }
            }

            .search-input::-ms-input-placeholder {
                /* Microsoft Edge */
                @include input-font; /* Firefox */
                @media screen and (min-width: 768px) {
                    font-size: 16px;
                }
            }
            .search-button {
                position: absolute;
                width: auto;
                height: 38px;
                right: 8px;
                top: 8px;
                background-color: $main-blue;
                z-index: 100;
                @include regular-font;
                line-height: 19px;
                color: $white;
                border-radius: 5px;
                padding: 8px 16px;
                cursor: pointer;
                &:hover {
                    background: #07348d;
                    box-shadow: 0px 5px 10px rgba(23, 90, 220, 0.2);
                }

                &:disabled {
                    background-color: rgba(7, 52, 141, 0.8);
                    cursor: not-allowed;
                }

                @media screen and (min-width: 768px) {
                    width: 150px;
                    right: 9px;
                    top: 8px;
                }
                @media screen and (min-width: 1200px) {
                    height: 50px;
                }
            }
        }
        .sell-now {
            @include flex-center;
            justify-content: center;
            gap: 16px;
            @media screen and (min-width: 768px) {
                justify-content: start;
            }
            p {
                @include regular-font;
                color: $white;
                @media screen and (min-width: 768px) {
                    font-size: 20px;
                }
            }
            .sell-now-btn {
                @include flex-center;
                justify-content: center;
                padding: 8px;
                gap: 10px;
                height: 35px;
                border-radius: 5px;
                @include regular-font;
                color: $white;
                background-color: $main-blue;
                min-width: 85px;
                cursor: pointer;
                &:hover {
                    background-color: #07348d;
                    box-shadow: 0px 5px 10px rgba(23, 90, 220, 0.2);
                }
            }
        }
    }
    
    .hero-overlay {
        background-color: black;
        opacity: 0.4;
        height: 100%;
        width: 100%;
    }
}
