.input-box {
    font-family: Sofia-Regular;
    height: 50px; // change this
    border: 1px solid $medium-grey;
    border-radius: 5px !important;
    padding: 0px 15px;
    font-size: 14px !important;
    font-weight: 400;
    background-color: #f5f8ff !important;
    svg {
        width: 21px;
        height: 21px;
    }

    input::placeholder {
        @include input-placeholder;
        font-size: 14px;
    }
    input {
        background-color: $extra-white !important;
        @include input-font;
        font-size: 16px !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        border: none;
        box-shadow: 0 0 0px 1000px $extra-white inset;
        -webkit-box-shadow: 0 0 0px 1000px $extra-white inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .ant-input-prefix {
        margin-right: 10px;
        color: $dark-gray;
        svg {
            width: 20px;
            height: 20px;
        }
    }
}
.number-input {
    height: 50px;
    @include input-font;
    border-radius: 5px !important;
    width: 100%;
    .phone-select {
        width: 128px !important;
        height: 100% !important;
        background-color: $extra-white;

        .ant-select-selector {
            height: 100% !important;
            background-color: transparent !important;
            border: none !important;
            border-radius: 0;
            .ant-select-selection-search {
                input {
                    height: 100%;
                }
            }
        }

        .ant-select-selection-item {
            display: flex;
        }
    }

    .ant-select-focused {
        .ant-select-selector {
            box-shadow: none !important;
        }
    }
    .phone-label {
        @include flex-center;
        img {
            max-height: 15px;
            width: 22px;
            max-width: 22px;
            margin-right: 8px;
            object-fit: contain;
        }

        span {
            @include input-font;
        }
    }
    .ant-input-group {
        background-color: $extra-white;
        height: 100% !important;
        border-radius: 5px !important;
        .ant-input-group-addon {
            font-size: unset;
            background-color: unset;
            .ant-select-show-arrow {
                height: 100%;
            }
        }
        .ant-input-affix-wrapper {
            height: 50px !important;
            background-color: $extra-white;
            input::placeholder {
                font-size: 16px;
                color: $medium-grey;
                font-weight: 400;
            }
            input {
                background-color: $extra-white !important;
            }
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus {
                border: none;
                box-shadow: 0 0 0px 1000px #f5f8ff inset;
                -webkit-box-shadow: 0 0 0px 1000px #f5f8ff inset;
                transition: background-color 5000s ease-in-out 0s;
            }
        }
        .ant-input-affix-wrapper-status-error {
            height: 100%;
            background: $extra-white !important;
        }
    }
    .ant-input-prefix {
        margin-right: 10px;
        color: $dark-gray;
        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.error-input {
    border: 1px solid $error-color !important;
    .ant-input-prefix {
        color: $dark-gray !important;
    }
}

.success-input {
    border: 1px solid #009e60 !important;
    .ant-input-prefix {
        color: $dark-gray !important;
    }
}
